import {
  Client,
  CombinedError,
  fetchExchange,
  subscriptionExchange,
  type Exchange,
} from '@urql/svelte';
import { ENV } from '../../config/environment';
import { createClient as createWSClient } from 'graphql-ws';
import { getAuthHeaders } from '../api/base.config';
import { authExchange } from '@urql/exchange-auth';
import { pipe, map } from 'wonka';
import type { GraphQLError } from 'graphql';
import * as Sentry from '@sentry/svelte';

const errorExchange: Exchange =
  ({ forward }) =>
  (ops$) => {
    return pipe(
      forward(ops$),
      map((result) => {
        if (result.error) {
          const { networkError, graphQLErrors } = result.error;
          const operation = result.operation;

          const handleScope =
            (type: 'GraphQL error' | 'Network error') =>
            (error: GraphQLError | Error) => {
              Sentry.withScope((scope) => {
                scope.setTag('kind', type);
                const { path, extensions } = error as any;
                scope.setContext(type, {
                  message: error.message,
                  path,
                  extensions,
                });

                if (path) {
                  scope.addBreadcrumb({
                    category: 'query-path',
                    message: path.join(' > '),
                    level: 'debug',
                  });
                }

                // Include operation details
                scope.setContext('operation', {
                  key: operation.key,
                  query: operation.query,
                  variables: operation.variables,
                  context: operation.context,
                });

                if (operation.context.url) {
                  scope.setExtra('url', operation.context.url);
                }

                Sentry.captureMessage(error.message, 'error');
              });
            };

          // Log each GraphQL error separately
          if (graphQLErrors && graphQLErrors.length > 0) {
            graphQLErrors.forEach(handleScope('GraphQL error'));
          }

          // Log network error if exists
          if (networkError) {
            handleScope('Network error')(networkError);
          }
        }

        return result;
      }),
    );
  };

const wsClient = createWSClient({
  url: `${ENV.wsUrl}/graphql`,
  lazy: true,
  connectionParams: async () => {
    const { Authorization } = await getAuthHeaders();
    return {
      Authorization,
    };
  },
});

export const client = new Client({
  url: `${ENV.apiUrl}/graphql`,
  exchanges: [
    authExchange(async (utils) => {
      const { Authorization } = await getAuthHeaders();
      let token = Authorization;
      return {
        refreshAuth: async () => {
          const { Authorization } = await getAuthHeaders();
          token = Authorization;
        },
        addAuthToOperation(operation) {
          if (!token) return operation;
          return utils.appendHeaders(operation, {
            Authorization: token,
          });
        },
        didAuthError(error, _operation) {
          if (error.response?.status === 401) {
            return true;
          }
          return false;
        },
        willAuthError: () => {
          return true;
        },
      };
    }),
    errorExchange,
    fetchExchange,
    subscriptionExchange({
      forwardSubscription(request) {
        const input = { ...request, query: request.query || '' };
        return {
          subscribe(sink) {
            const unsubscribe = wsClient.subscribe(input, sink);
            return { unsubscribe };
          },
        };
      },
    }),
  ],
});

export const getGqlErrorCode = (e: any): string | undefined => {
  if (!(e instanceof CombinedError)) {
    return undefined;
  }
  return (
    (e.graphQLErrors.find((p) => p.extensions.code)?.extensions
      .code as string) ?? undefined
  );
};

export const getGqlErrorMessage = (e: any) => {
  if (!(e instanceof CombinedError)) {
    return e.message;
  }
  return e.graphQLErrors.map((p) => p.message).join('\n');
};
