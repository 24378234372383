<script lang="ts">
  import { flip } from 'svelte/animate';
  import { fly } from 'svelte/transition';
  import toastsStore, { type Toast, ToastType } from '../../stores/toastsStore';
  import InfoIcon from './icons/InfoIcon.svelte';
  import CloseIcon from './icons/CloseIcon.svelte';

  let toasts: Toast[] = [];
  toastsStore.subscribe((value: Toast[]) => {
    toasts = value;
  });
</script>

<div class="toasts">
  {#each toasts as toast}
    <div class={`toast toast-${toast.type}`} transition:fly={{ y: 30 }}>
      <div class="icon">
        <InfoIcon fillClass={`icon-${ToastType[toast.type]}`} />
      </div>
      <div class="content">
        <div class="message">{@html toast.message}</div>
        {#if toast.description}
          <div class="description">{toast.description}</div>
        {/if}
      </div>
      <!-- svelte-ignore a11y-click-events-have-key-events -->
      <span on:click={() => toastsStore.clear(toast.id)}>
        <CloseIcon className="close-icon" />
      </span>
    </div>
  {/each}
</div>

<style>
  .toasts {
    position: fixed;
    top: 1rem;
    right: 1rem;
    margin: 0;
    padding: 0;
    z-index: 9999;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
  }

  .toast {
    display: grid;
    grid-template-columns: 1rem auto 1rem;
    column-gap: 1rem;
    border-radius: 5px;
    padding: 10px;
    margin-bottom: 10px;
    color: var(--color-glass-700);
    font-weight: 500;

    background-color: var(--color-glass-200);
    box-shadow:
      0px 0px 1px 0px rgba(0, 0, 0, 0.04),
      0px 4px 8px 0px rgba(0, 0, 0, 0.04),
      0px 16px 24px 0px rgba(0, 0, 0, 0.04),
      0px 24px 32px 0px rgba(0, 0, 0, 0.04);
  }

  .toast-info {
    background-color: var(--color-electric-200);
  }

  .toast-warning {
    background-color: var(--color-yellow-200);
  }

  .toast-success {
    background-color: var(--color-green-200);
  }

  .toast-danger {
    background-color: var(--color-red-200);
  }

  .icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 24px;
    height: 24px;
  }

  .icon :global(.icon-default) {
    fill: var(--color-glass-400);
  }
  .icon :global(.icon-info) {
    fill: var(--color-electric-400);
  }
  .icon :global(.icon-warning) {
    fill: var(--color-yellow-400);
  }
  .icon :global(.icon-success) {
    fill: var(--color-green-400);
  }
  .icon :global(.icon-danger) {
    fill: var(--color-red-400);
  }

  .content {
    display: flex;
    flex-direction: column;
    gap: 0.25rem;
  }

  .message {
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.16px;
  }

  .description {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.14px;
  }

  .toast :global(.close-icon:hover) {
    width: 1.25rem;
    height: 1.25rem;
    cursor: pointer;
    transform: scale(1.1);
  }
</style>
